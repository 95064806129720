import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-scientist',
    templateUrl: './homeone-scientist.component.html',
    styleUrls: ['./homeone-scientist.component.scss']
})
export class HomeoneScientistComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our People",
            title: 'Tim Kami',
            paragraphText: 'Ilmu data di isi oleh orang orang yang memiliki passion dibidang teknologi dan data science'
        }
    ]
    singleScientistBox: scientistBoxContent[] = [
        // {
        //     img: 'assets/img/scientist/hasan.jpg',
        //     title: 'Hasan Basri',
        //     designation: 'Co-Founder, CEO',
        //     facebookLink: '#',
        //     facebookIcon: 'bx bxl-facebook',
        //     twitterLink: '#',
        //     twitterIcon: 'bx bxl-twitter',
        //     instagramLink: '#',
        //     instagramIcon: 'bx bxl-instagram',
        //     linkedinLink: '#',
        //     linkedinIcon: 'bx bxl-linkedin'
        // },
        {
            img: 'assets/img/scientist/derry.jpg',
            title: 'Derry Wijaya Ph.D.',
            designation: 'Co-Founder, Chief Data Scientist',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: 'https://www.linkedin.com/in/derry-wijaya-577b80178',
            linkedinIcon: 'bx bxl-linkedin'
        },
        {
            img: 'assets/img/scientist/al.jpg',
            title: 'Syahrial Dahler',
            designation: 'Co-Founder, Chief Engineer',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook', 
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        }
        ,      
        //{
        //    img: 'assets/img/scientist/img4.png',
        //    title: 'Marie Curie',
        //    designation: 'Data Scientist',
        //    facebookLink: '#',
        //    facebookIcon: 'bx bxl-facebook',
        //    twitterLink: '#',
        //    twitterIcon: 'bx bxl-twitter',
        //    instagramLink: '#',
        //    instagramIcon: 'bx bxl-instagram',
        //    linkedinLink: '#',
        //    linkedinIcon: 'bx bxl-linkedin'
        //}
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class scientistBoxContent {
    img: string;
    title: string;
    designation: string;
    facebookLink: string;
    facebookIcon: string;
    twitterLink: string;
    twitterIcon: string;
    instagramLink: string;
    instagramIcon: string;
    linkedinLink: string;
    linkedinIcon: string;
}
