import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConversationalForm } from 'conversational-form';

@Component({
  selector: 'app-covidbot',
  templateUrl: './covidbot.component.html',
  styleUrls: ['./covidbot.component.scss']
})
export class CovidbotComponent implements OnInit, AfterViewInit  {

  @ViewChild('myCF') myCF: ElementRef;
    cf: any;
    formFields = [
        {
            //"id": "persetujuan_status",
            'tag': 'fieldset',
            'type': 'radio',
            'cf-questions': 'Tujuan pemeriksaan mandiri virus corona ini adalah untuk membantuAnda membuat keputusan untuk mencari perawatan medis yang tepat. Sistem ini tidak ditujukan untuk diagnosis atau pengobatan penyakit termasuk COVID-19. Anda setuju untuk melanjutkan?',
            "name": "persetujuan",
            "cf-label": "iya",
            "value": "iya",
            "children": [
                { "tag": "input", "cf-label": "Iya", "value": "IYA", 'type': 'radio', },
                { "tag": "input", "cf-label": "Tidak", "value": "TIDAK", 'type': 'radio', },
                
            ]
            //"children": [
            //    {
            //        "tag": "input",
            //        "type": "radio",
            //        "name": "city",
            //        "cf-label": "iya"
            //    },
            //    {
            //        "tag": "input",
            //        "type": "radio",
            //        "name": "city",
            //        "cf-label": "tidak"
            //    }
            //]
        },
        //{
        //    'tag': 'fieldset',
        //    'type': 'radio',
        //    'cf-questions': 'Tujuan pemeriksaan mandiri virus corona ini adalah untuk membantuAnda membuat keputusan untuk mencari perawatan medis yang tepat. Sistem ini tidak ditujukan untuk diagnosis atau pengobatan penyakit termasuk COVID-19. Anda setuju untuk melanjutkan?',
        //    "name": "persetujuan",
        //    "cf-label": "tidak",
        //    "value": "tidak",
        //},
        //{
        //    'id': 'mortgage_reason',
        //    'tag': "fieldset",
        //    'type': 'radio',
        //    'name': 'mortgage_reason',
        //    'cf-questions': 'Why do you require the fund?',
        //    "required": "required",
        //    "children": [
        //        { "tag": "input", "cf-label": "<i class=\"fa fa-percent\"> Better Rate</i>", "value": "BETTERRATE", 'type': 'radio', },
        //        { "tag": "input", 'cf-label': "<i class=\"fa fa-home\"> New Home</i>", 'value': 'NEWHOME', 'type': 'radio', },
        //        { "tag": "input", "cf-label": "<i class=\"fa fa-plane\"> Holiday</i> ", "value": "HOLIDAY", 'type': 'radio', },
        //        { "tag": "input", "cf-label": "<i class=\"fa fa-question-circle-o\"> Other</i>", "value": "OTHER", 'type': 'radio', }
        //    ]
        //},
        {
            //"id": "lokasi",
            "tag": "input",
            'type': 'text',
            "name": "lokasi", 
            "cf-questions": "Dimana Lokasi Anda?",
            "cf-conditional-persetujuan": "IYA",
            "required": "required",
        },
        {
            'tag': 'input',
            'type': 'text',
            'name': 'firstname',
            'cf-questions': 'What is your firstname?'
        },
        //{
        //    'tag': 'fieldset',
        //    'type': 'radio',
        //    'cf-questions': 'Anda Setuju dengan term dan kondisi?',
        //     "children": [
        //        {
        //            "tag": "input",
        //            "type": "radio",
        //            "name": "setuju",
        //            "cf-label": "iya"
        //        },
        //        {
        //            "tag": "input",
        //            "type": "radio",
        //            "name": "tidak-setuju",
        //            "cf-label": "tidak"
        //        }
        //    ]
        //},
        //{
        //    'tag': 'input',
        //    'type': 'text',
        //    'name': 'lastname',
        //    'cf-questions': 'What is your lastname?'
        //},
        
    ];

    ngOnInit() {
        
    }

    ngAfterViewInit() {
        console.log('init', this)
        this.cf = ConversationalForm.startTheConversation({
            options: {
                submitCallback: this.submitCallback.bind(this),
                preventAutoFocus: true,
            },
            tags: this.formFields
        });
        this.myCF.nativeElement.appendChild(this.cf.el);
    }

    submitCallback() {
        var formDataSerialized = this.cf.getFormData(true);
        console.log("Formdata, obj:", formDataSerialized);
        this.cf.addRobotChatResponse("You are done. Check the dev console for form data output.")
    }

}
