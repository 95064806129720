<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                    <p></p>
                    <!--<ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>-->
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Home</a></li>
                        <li><a routerLink="">About</a></li>
                        <!--<li><a routerLink="/case-studies-2-columns">Case Studies</a></li>
                        <li><a routerLink="/blog-1">Our Blog</a></li>-->
                        <li><a routerLink="">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Our Team</a></li>
                        <!--<li><a routerLink="/services-1">Our Services</a></li>-->
                        <!--<li><a routerLink="/testimonials">Testimonials</a></li>
                        <li><a routerLink="/services-details-one">SaaS Solutions</a></li>
                        <li><a routerLink="/services-details-two">eCommerce</a></li>-->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <!--<li><i class='bx bx-map'></i>175 5th Ave, New York, NY 10010, United States</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">+1 (123) 456 7890</a></li>-->
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@ilmudata.com">info@ilmudata.com</a></li>
                        <!--<li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2020 <strong>PT. Kecerdasan Buatan Anak Bangsa</strong></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <!--<li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>
