import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Expertises',
            title: 'Keahlian kami yang membuat kami berbeda',
            paragraphText: 'Kami sudah berkecimpung dibidang Artificial Intelligence sejak lama '
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-money',
            title: 'Machine Learning',
            paragraphText: 'Machine Learning merupakan dasar dari Artificial Inteligence saat ini. ML merupakan teknik yang dipakai agar komputer bisa belajar dari data secara kerkesinambungan. ',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-rocket',
            title: 'Natural Language Processing',
            paragraphText: 'NLP merupakan teknik Machine Learning model yang dipakai supaya komputer dan mesin mengerti bahasa manusia',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Deep Learning and Neural Network',
            paragraphText: 'Deep Learnig merupakan model Machine Learning paling mutakhir yang berbasis deep neural network. Teknik ini dipakai disemua cabang AI untuk mendapatkan performance paling bagus. ',
            link: 'services-details',
            linkText: 'View Details'
        }
       
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}
