import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Pilkada DKI 2017',
            title: 'Sentiment sosial media terdap pasangan calon gubernur DKI',
            paragraphText1: 'Analisa terhadap penggunaan media sosial (Twitter) dalam kampanye pasangan calon gubernur DKI Jakarta untuk pilkada tahun 2017.',
            paragraphText2: '',
            link: 'pilkadadki2017',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/pilkadadki2017.png'
        }
        //,
        //{
        //    subTitle: 'Case study #2',
        //    title: 'Mathematics, Advanced Statistics in Python',
        //    paragraphText1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
        //    paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
        //    link: 'case-studies-details-1',
        //    linkText: 'Details More',
        //    linkIcon: 'flaticon-view',
        //    img: 'assets/img/case-study/img2.jpg'
        //}
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}
