<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Quote Generation</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Quote Generation</li>
                
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
       
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                   

                    <div class="article-content">
                        <h4>Generate kata kata mutiaramu dengan memakai KBAB Bot</h4>
                        <p>Dengan memakai large language model kami mencoba mengenarate kata kata mutiara baru.</p>
                        <p> Kamu bisa mengeluarkan kata kata mutiara dengan bantuan quote generator ini. Generator ini akan mencoba menghasilkan 
                            kata kata mutiara baru yang belum pernah diucapkan oleh siapapun.
                        </p>
                        <p> Masukkan maksimum 3 kata yang kamu inginkan ada di dalam kata kata mutiaramu. Contoh: makan gunung senang</p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="includedText" class="form-control" id="includedText" #includedText>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="generatorName" class="form-control" [(ngModel)]="username" id="generatorName" placeholder="nama kamu" #generatorName>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button class="default-btn" (click)="generateQuote(includedText.value)"><i class="flaticon-tick" ></i>Generate<span></span></button>
                            </div>
                        </div>
                        
                        <blockquote *ngIf="generatedData" class="wp-block-quote">
                            <p >{{generatedData.text_baru}}</p>
    
                            <cite>{{username}}</cite>
                        </blockquote>
                        
                        
                        <p *ngIf="errorMessage">{{errorMessage}}</p>
                    </div>

                    
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                  
                  
                    <section class="widget widget_tracer_posts_thumb">
                        <h3 class="widget-title">Famous quote of the day</h3>

                        <div *ngIf="qData" class="courses-content">
                            
                            <p> Kata kata mutiara ini adalah kata kata mutiara yang pernah diucapkan oleh seseorang.</p>
                           
                                <p><b>{{qData.kata_kata}}</b></p>
        
                                <cite *ngIf="qData.author">{{qData.author}}</cite>
                            
        
                           
                
                        </div>

                        
                    </section>

                    
                </aside>
            </div>
        </div>
    </div>
</section>


