<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Tanya tentang hukum</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Chat Hukum</li>
                
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
       
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h4>KBAB Bot akan menjawab pertanyaanmu tentang hukum di Indonesia</h4>
                        <p>Dengan memakai large language model kami mencoba menjawab pertanyaan tentang hukum di Indonesia.</p>
                        <p><b>Catatan: Ini bukan bantuan hukum, hanya untuk kepentingan pendidikan !!!!</b></p>
                        
                        <div >
                           
                           

                            <div class="comments-area">
                                
        
                                <ol *ngFor="let item of chatData;" class="comment-list">
                                    <div class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    
                                                    <b class="fn">Pertanyaan</b>
                                                    
                                                </div>
        
                                                
                                            </footer>
        
                                            <div class="comment-content">
                                                <p>{{item.question}}</p>
                                            </div>
        
                                        </article>
        
                                      
                                    </div>
        
                                    <div class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    
                                                    <b class="fn">KBAB Bot</b>
                                                   
                                                </div>
        
                                               
                                            </footer>
        
                                            <div class="comment-content">
                                                <p>{{item.answer}}</p>
                                            </div>
        
                                            
                                        </article>
        
                                        
                                    </div>
                                </ol>
                            </div>
                        </div>
                     
                        <div class="row">
                            
                            <div class="col-md-9">
                                <div class="form-group">
                                    <input type="text" name="generatorName" class="form-control" [(ngModel)]="question" id="generatorName" placeholder="pertanyaan kamu" #generatorName>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button class="default-btn" (click)="askQuestion()"><i class="flaticon-tick" ></i>Tanya<span></span></button>
                            </div>
                        </div>
                        
                        
                        
                        
                        <p *ngIf="errorMessage">{{errorMessage}}</p>
                    </div>

                    
                </div>
            </div>

           
        </div>
    </div>
</section>



