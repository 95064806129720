import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GeneratedQuoteData, QuoteData } from 'src/app/services/models.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  qData! : QuoteData; 
  username! :string;
  errorMessage !:string;
  generatedData! : GeneratedQuoteData;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getQuoteData();
  }

  getQuoteData()
  {
    this.api.getQuote().subscribe((res :QuoteData) => 
    {
      // console.log(res);
      this.qData = res;
      const splitdata = this.qData.kata_kata.split("-");
      this.qData.kata_kata = splitdata[0];
      if (splitdata.length > 1)
      {
        this.qData.author = splitdata[1];
      }
      console.log(this.qData);
    });
  }

  generateQuote(inputText)
  {
    this.errorMessage = "";
    if (!inputText)
    {
      this.errorMessage = "Masukkan kata kata pilihan"
      return;
    }

    const splitted = inputText.split(" ");
    if (splitted.length > 3)
    {
      this.errorMessage = "Maksimum 3 kata pilihan"
      return;
    } 
    console.log("masuk ga sih")
    const obj ={"req": inputText}
    this.api.generateQuote(obj).subscribe(
      (data:GeneratedQuoteData) => {
        console.log(data);
        this.generatedData = data;
        
      },
      (error) => {
        console.log(error)
      }
    )
  }

}
