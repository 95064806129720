import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ConversationalForm } from 'conversational-form';
import { pageTitle } from '../../../services/models.service';

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
    styleUrls: ['./covid.component.scss'],
})
export class CovidComponent implements OnInit {

    pageTitleArea: pageTitle[] = [
        {
            title: 'Test Mandiri Gejala Covid',
            subTitle: 'Test Mandiri gejala covid berdasarkan gejala yang diumumkan oleh CDC Amerika Serikat',
        }
    ]
    


    constructor() {
       
    }

    ngOnInit() {
        
        
    }

    //onSubmit() {
    //    //this.payLoad = JSON.stringify(this.form.getRawValue());
    //}

}
