import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QuoteData } from './models.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = 'https://kbabapi-hzvbfiwcoq-de.a.run.app/';
  // private apiUrl = ' http://127.0.0.1:8080/';

  constructor(private http: HttpClient) { }


  getQuote() : Observable<QuoteData>{
    const quote_url = this.apiUrl+"quote" 
    return this.http.get(quote_url) as Observable<QuoteData>;   
  }

  generateQuote(obj){
    const quote_url = this.apiUrl+"generate-quote" 
   
    return this.http.post(quote_url , obj);
  }

  askHukum(obj){
    const quote_url = this.apiUrl+"chat-hukum" 
    console.log(obj)
    return this.http.post(quote_url , obj);
  }

}
