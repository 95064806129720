import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Services',
            title: 'Layanan profesional untuk organisasi dan individu',
            paragraphText: 'Kami menyediakan servis sesuai dengan kebutuhan anda sebagai organisasi atau perusahaan untuk memanfaatkan perkembangan '+
                           'teknologi 4.0 saat ini.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Konsultasi Riset',
            paragraphText: 'Kami bisa membantu para peneliti di berbagai bidang untuk memakai machine learning\'s state of the art algorithms untuk menghasilkan kajian kuantitatif terhadap data dan masalah yang dihadapi.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Pelatihan Data Science',
            paragraphText: 'Bagi praktisi maupun masyarakat umum, kami menyediakan beberapa paket pelatihan data science.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Solusi dan Konsultasi Sistem',
            paragraphText: 'Kami membantu organisasi anda meng- utilize asset ini untuk melakukan prediksi dan analisis terhadap data anda dengan menggunakan teknik machine learning yang sesuai dengan jenis data yang dimiliki.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        
        //{
        //    icon: 'assets/img/services/icon4.png',
        //    title: 'Predictive Analytics',
        //    paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
        //    link: 'services-details',
        //    linkText: 'Read More',
        //    linkIcon: 'flaticon-right'
        //},
        //{
        //    icon: 'assets/img/services/icon5.png',
        //    title: 'Software Development',
        //    paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
        //    link: 'services-details',
        //    linkText: 'Read More',
        //    linkIcon: 'flaticon-right'
        //},
        //{
        //    icon: 'assets/img/services/icon6.png',
        //    title: 'Elastic Solutions',
        //    paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
        //    link: 'services-details',
        //    linkText: 'Read More',
        //    linkIcon: 'flaticon-right'
        //}
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services-2',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}
