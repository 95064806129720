import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor() { }
}

export class pageTitle {
    title: string;
    subTitle: string;
}
export class Image {
    img: string;
}
export class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    img1: string;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4: string;
}
export class ImportantFacts {
    title: string;
}
export class Info {
    icon: string;
    title: string;
    subTitle: string;
}

export class QuoteData {
    kata_kata: string;
    author:string;
}
export class GeneratedQuoteData {
    text_baru: string;
}

export class ChatData{
    question: string;
    answer: string;
}


