import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ChatData } from 'src/app/services/models.service';

@Component({
  selector: 'app-chat-hukum',
  templateUrl: './chat-hukum.component.html',
  styleUrls: ['./chat-hukum.component.scss']
})
export class ChatHukumComponent implements OnInit {

  errorMessage !:string;
  question! : string;
  chatLog : string = "";
  chatData : ChatData[]



  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.chatData = [];
  }

  askQuestion()
  {
    const obj={"hist":this.chatLog,
              "question": this.question
      }

      this.api.askHukum(obj).subscribe(
        (data:ChatData) => {
          console.log(data);
          this.chatLog = this.chatLog +"\n\n"+this.question +"\n\n"+ data.answer
          const chat: ChatData = new ChatData();
          chat.answer = data.answer;
          chat.question = this.question
          this.chatData.push(chat);
          
        },
        (error) => {
          console.log(error)
        }
      )

  }

}
