<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <h4>{{pageTitle.subTitle}}</h4>
            <div class="case-studies-details-desc">
                <span class="sub-title">Peringatan</span>
            </div>
            <br />
            <p>
                Tujuan pemeriksaan mandiri virus corona ini adalah untuk membantu Anda membuat keputusan untuk mencari perawatan medis yang tepat.
                Sistem ini tidak ditujukan untuk diagnosis atau pengobatan penyakit termasuk COVID-19
            </p>

            <!--<ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{pageTitle.title}}</li>
        <li>{{pageTitle.subTitle}}</li>
    </ul>-->
        </div>
    </div>

    <!--<div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>-->
</section>

<section class="case-studies-details-area ptb-100">
    <div class="container">
        <div class="row">
           
            <div class="col-lg-12 col-md-12 ">
            
                <!--<iframe width="100%" height="720px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=zDJ91SHBj0iwe9_nBWgMcRQCDLInf_lHmZ_BLof_T6tUQVJMV0ZJMENCQTBWOVZRMzA1QzdMUFM4Qi4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style="border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>-->
                <!--<p>Below is a chat box</p>-->
                <app-covidbot></app-covidbot>
                <!--<div class="myCF" #myCF style="height:400px; width:400px"></div>-->

                <div>
                    <!--<form (ngSubmit)="onSubmit()" [formGroup]="form">

            <div *ngFor="let question of questions" class="form-row">
                <app-question [question]="question" [form]="form"></app-question>
            </div>

            <div class="form-row">
                <button type="submit" [disabled]="!form.valid">Save</button>
            </div>
        </form>

        <div *ngIf="payLoad" class="form-row">
            <strong>Saved the following values</strong><br>{{payLoad}}
        </div>-->

                </div>

            </div>
        </div>
        <br />
        
    </div>
</section>
